const LEADERBOARDS = {
    'completion-score': {
        name: 'Completion Score',
        description: 'Ranks players based on a weighted score of completed content. 100 points for mounts, leveled pets, toys, titles, and exalted reputations. 10 points for recipes and quests. 3 points per achievement point. 1 point for appearance sources. Weights are subject to change.'
    },
    'completion-score-obtainable': {
        name: 'Completion Score (Obtainable Only)',
        short: 'Completion Score',
        description: 'Ranks players based on a weighted score of completed content. 100 points for mounts, leveled pets, toys, titles, and exalted reputations. 10 points for recipes and quests. 3 points per achievement point. 1 point for appearance sources. Weights are subject to change. Only includes content that is currently obtainable in-game, with the exception of appearance sources.'
    },
    'completion-count': {
        name: 'Completion Count',
        description: 'Ranks players based on an unweighted count of completed content, including achievements, mounts, pets, toys, titles, exalted reputations, recipes, quests, and appearance sources.'
    },
    'completion-count-obtainable': {
        name: 'Completion Count (Obtainable Only)',
        short: 'Completion Count',
        description: 'Ranks players based on an unweighted count of completed content, including achievements, mounts, pets, toys, titles, exalted reputations, recipes, quests, and appearance sources. Only includes content that is currently obtainable in-game, with the exception of appearance sources.'
    },
    'achievement-points': {
        name: 'Achievement Points',
        description: 'Ranks players based on their achievement points. Alliance are given extra points for completing all "Quests: Battle for Azeroth" achievements, due to differences with Horde.'
    },
    achievements: {
        name: 'Achievements',
        description: 'Ranks players based on their total unique achievements completed, including both Alliance and Horde. Does not include Feats of Strength or Legacy achievements.'
    },
    feats: {
        name: 'Feats of Strength',
        description: 'Ranks players based on their total unique Feats of Strength, including both Alliance and Horde.'
    },
    legacy: {
        name: 'Legacy Achievements',
        description: 'Ranks players based on their total unique Legacy achievements completed, including both Alliance and Horde.'
    },
    'account-mounts': {
        name: 'Account-Wide Mounts',
        short: 'Mounts',
        description: 'Ranks players based on their total unique mounts across all their characters, including Alliance, Horde, and class-specific mounts.'
    },
    'account-mounts-obtainable': {
        name: 'Account-Wide Mounts (Obtainable Only)',
        short: 'Mounts',
        description: 'Ranks players based on their total unique mounts across all their characters, including Alliance, Horde, and class-specific mounts. Only includes mounts that are currently obtainable in-game.'
    },
    'character-mounts': {
        name: 'Character-Specific Mounts',
        short: 'Mounts',
        description: 'Ranks players based on the most mounts they can use on any single character. Alts are not shown in this leaderboard.'
    },
    'character-mounts-obtainable': {
        name: 'Character-Specific Mounts (Obtainable Only)',
        short: 'Mounts',
        description: 'Ranks players based on the most mounts they can use on any single character. Only includes mounts that are currently obtainable in-game. Alts are not shown in this leaderboard.'
    },
    pets: {
        name: 'Pets',
        description: 'Ranks players based on their amount of unique pets.'
    },
    'pets-obtainable': {
        name: 'Pets (Obtainable Only)',
        short: 'Pets',
        description: 'Ranks players based on their amount of unique pets. Only includes pets that are currently obtainable in-game.'
    },
    'pets-score': {
        name: 'Pet Score',
        description: 'Ranks players based on the level and rarity of their unique pets. Each pet has their level multiplied by 4 for rare, 3 for uncommon, 2 for common, and 1 for poor. Therefore a level 25 rare pet will be given 100 points.'
    },
    'pets-score-obtainable': {
        name: 'Pet Score (Obtainable Only)',
        short: 'Pet Score',
        description: 'Ranks players based on the level and rarity of their unique pets. Each pet has their level multiplied by 4 for rare, 3 for uncommon, 2 for common, and 1 for poor. Therefore a level 25 rare pet will be given 100 points. Only includes pets that are currently obtainable in-game.'
    },
    alts: {
        name: 'Alts',
        description: 'Ranks players based on their amount of max level characters.'
    },
    'alts-score': {
        name: 'Alt Score',
        description: 'Ranks players based on the sum of levels across all their characters.'
    },
    'account-titles': {
        name: 'Account-Wide Titles',
        short: 'Titles',
        description: 'Ranks players based on their total unique titles across all their characters.'
    },
    'account-titles-obtainable': {
        name: 'Account-Wide Titles (Obtainable Only)',
        short: 'Titles',
        description: 'Ranks players based on their total unique titles across all their characters. Only includes titles that are currently obtainable in-game.'
    },
    'character-titles': {
        name: 'Character-Specific Titles',
        short: 'Titles',
        description: 'Ranks players based on the most titles they can use on any single character. Alts are not shown in this leaderboard.'
    },
    'character-titles-obtainable': {
        name: 'Character-Specific Titles (Obtainable Only)',
        short: 'Titles',
        description: 'Ranks players based on the most titles they can use on any single character. Only includes titles that are currently obtainable in-game. Alts are not shown in this leaderboard.'
    },
    'account-recipes': {
        name: 'Account-Wide Recipes',
        short: 'Recipes',
        description: 'Ranks players based on their total unique recipes across all their characters. This includes both primary and secondary professions.'
    },
    'account-recipes-obtainable': {
        name: 'Account-Wide Recipes (Obtainable Only)',
        short: 'Recipes',
        description: 'Ranks players based on their total unique recipes across all their characters. This includes both primary and secondary professions. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-alchemy': {
        name: 'Account-Wide Alchemy Recipes',
        short: 'Alchemy',
        description: 'Ranks players based on their total unique alchemy recipes across all their characters.'
    },
    'account-recipes-alchemy-obtainable': {
        name: 'Account-Wide Alchemy Recipes (Obtainable Only)',
        short: 'Alchemy',
        description: 'Ranks players based on their total unique alchemy recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-blacksmithing': {
        name: 'Account-Wide Blacksmithing Recipes',
        short: 'Blacksmithing',
        description: 'Ranks players based on their total unique blacksmithing recipes across all their characters.'
    },
    'account-recipes-blacksmithing-obtainable': {
        name: 'Account-Wide Blacksmithing Recipes (Obtainable Only)',
        short: 'Blacksmithing',
        description: 'Ranks players based on their total unique blacksmithing recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-cooking': {
        name: 'Account-Wide Cooking Recipes',
        short: 'Cooking',
        description: 'Ranks players based on their total unique cooking recipes across all their characters.'
    },
    'account-recipes-cooking-obtainable': {
        name: 'Account-Wide Cooking Recipes (Obtainable Only)',
        short: 'Cooking',
        description: 'Ranks players based on their total unique cooking recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-enchanting': {
        name: 'Account-Wide Enchanting Recipes',
        short: 'Enchanting',
        description: 'Ranks players based on their total unique enchanting recipes across all their characters.'
    },
    'account-recipes-enchanting-obtainable': {
        name: 'Account-Wide Enchanting Recipes (Obtainable Only)',
        short: 'Enchanting',
        description: 'Ranks players based on their total unique enchanting recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-engineering': {
        name: 'Account-Wide Engineering Recipes',
        short: 'Engineering',
        description: 'Ranks players based on their total unique engineering recipes across all their characters.'
    },
    'account-recipes-engineering-obtainable': {
        name: 'Account-Wide Engineering Recipes (Obtainable Only)',
        short: 'Engineering',
        description: 'Ranks players based on their total unique engineering recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-inscription': {
        name: 'Account-Wide Inscription Recipes',
        short: 'Inscription',
        description: 'Ranks players based on their total unique inscription recipes across all their characters.'
    },
    'account-recipes-inscription-obtainable': {
        name: 'Account-Wide Inscription Recipes (Obtainable Only)',
        short: 'Inscription',
        description: 'Ranks players based on their total unique inscription recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-jewelcrafting': {
        name: 'Account-Wide Jewelcrafting Recipes',
        short: 'Jewelcrafting',
        description: 'Ranks players based on their total unique jewelcrafting recipes across all their characters.'
    },
    'account-recipes-jewelcrafting-obtainable': {
        name: 'Account-Wide Jewelcrafting Recipes (Obtainable Only)',
        short: 'Jewelcrafting',
        description: 'Ranks players based on their total unique jewelcrafting recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-leatherworking': {
        name: 'Account-Wide Leatherworking Recipes',
        short: 'Leatherworking',
        description: 'Ranks players based on their total unique leatherworking recipes across all their characters.'
    },
    'account-recipes-leatherworking-obtainable': {
        name: 'Account-Wide Leatherworking Recipes (Obtainable Only)',
        short: 'Leatherworking',
        description: 'Ranks players based on their total unique leatherworking recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'account-recipes-tailoring': {
        name: 'Account-Wide Tailoring Recipes',
        short: 'Tailoring',
        description: 'Ranks players based on their total unique tailoring recipes across all their characters.'
    },
    'account-recipes-tailoring-obtainable': {
        name: 'Account-Wide Tailoring Recipes (Obtainable Only)',
        short: 'Tailoring',
        description: 'Ranks players based on their total unique tailoring recipes across all their characters. Only includes recipes that are currently obtainable in-game.'
    },
    'character-recipes': {
        name: 'Character-Specific Recipes',
        short: 'Recipes',
        description: 'Ranks players based on the most recipes learned on any single character. This includes both primary and secondary professions. Alts are not shown in this leaderboard.'
    },
    'character-recipes-obtainable': {
        name: 'Character-Specific Recipes (Obtainable Only)',
        short: 'Recipes',
        description: 'Ranks players based on the most recipes learned on any single character. This includes both primary and secondary professions. Only includes recipes that are currently obtainable in-game. Alts are not shown in this leaderboard.'
    },
    'account-reputations': {
        name: 'Account-Wide Reputations',
        short: 'Reputations',
        description: 'Ranks players based on their total unique reputations at Exalted (or equivalent) across all their characters, including both Alliance and Horde.'
    },
    'account-reputations-obtainable': {
        name: 'Account-Wide Reputations (Obtainable Only)',
        short: 'Reputations',
        description: 'Ranks players based on their total unique reputations at Exalted (or equivalent) across all their characters, including both Alliance and Horde. Only includes reputations that are currently obtainable in-game.'
    },
    'character-reputations': {
        name: 'Character-Specific Reputations',
        short: 'Reputations',
        description: 'Ranks players based on the most reputations at Exalted (or equivalent) on any single character. Alts are not shown in this leaderboard.'
    },
    'character-reputations-obtainable': {
        name: 'Character-Specific Reputations (Obtainable Only)',
        short: 'Reputations',
        description: 'Ranks players based on the most reputations at Exalted (or equivalent) on any single character. Only includes reputations that are currently obtainable in-game. Alts are not shown in this leaderboard.'
    },
    'account-quests': {
        name: 'Account-Wide Quests',
        short: 'Quests',
        description: 'Ranks players based on their total unique quests completed across all their characters, including both Alliance and Horde. World quests, dailies, weeklies, and other repeatable quests are not counted.'
    },
    'account-quests-obtainable': {
        name: 'Account-Wide Quests (Obtainable Only)',
        short: 'Quests',
        description: 'Ranks players based on their total unique quests completed across all their characters, including both Alliance and Horde. World quests, dailies, weeklies, and other repeatable quests are not counted. Only includes quests that are currently obtainable in-game.'
    },
    'character-quests': {
        name: 'Character-Specific Quests',
        short: 'Quests',
        description: 'Ranks players based on the most quests completed on any single character. World quests, dailies, weeklies, and other repeatable quests are not counted. Alts are not shown in this leaderboard.'
    },
    'character-quests-obtainable': {
        name: 'Character-Specific Quests (Obtainable Only)',
        short: 'Quests',
        description: 'Ranks players based on the most quests completed on any single character. World quests, dailies, weeklies, and other repeatable quests are not counted. Only includes quests that are currently obtainable in-game. Alts are not shown in this leaderboard.'
    },
    'account-toys': {
        name: 'Account-Wide Toys',
        short: 'Toys',
        description: 'Ranks players based on the number of toys collected across all their characters.'
    },
    'account-toys-obtainable': {
        name: 'Account-Wide Toys (Obtainable Only)',
        short: 'Toys',
        description: 'Ranks players based on the number of toys collected across all their characters. Only includes toys that are currently obtainable in-game.'
    },
    'account-appearance-sources': {
        name: 'Account-Wide Appearance Sources',
        short: 'Appearance Sources',
        description: 'Ranks players based on the number of appearance sources unlocked for all gear slots across all their characters. This number is provided directly by the Blizzard Achievement API, and seems to count item sources rather than unique appearances.'
    },
    'account-appearances': {
        name: 'Account-Wide Appearances',
        short: 'Appearances',
        description: 'Ranks players based on the number of unique appearances unlocked for all gear slots across all their characters.'
    },
    'account-appearances-obtainable': {
        name: 'Account-Wide Appearances (Obtainable Only)',
        short: 'Appearances',
        description: 'Ranks players based on the number of unique appearances unlocked for all gear slots across all their characters. Only includes appearances that are currently obtainable in-game.'
    },
    honorlevel: {
        name: 'Honor Level',
        description: 'Ranks players based on their honor level.'
    },
    'account-kills': {
        name: 'Account-Wide Honorable Kills',
        short: 'Honorable Kills',
        description: 'Ranks players based on their total honorable kills across all their characters.'
    },
    'character-kills': {
        name: 'Character-Specific Honorable Kills',
        short: 'Honorable Kills',
        description: 'Ranks players based on the most honorable kills on any single character. Alts are not shown in this leaderboard.'
    },

    // for collection categories and changelog
    mounts: {
        name: 'Mounts'
    },
    titles: {
        name: 'Titles'
    },
    recipes: {
        name: 'Recipes'
    },
    reputations: {
        name: 'Reputations'
    },
    quests: {
        name: 'Quests'
    },
    toys: {
        name: 'Toys'
    },
    appearances: {
        name: 'Appearances',
    },
    heirlooms: {
        name: 'Heirlooms'
    },
    hunterpets: {
        name: 'Hunter Pets'
    },
    soulshapes: {
        name: 'Soulshapes'
    },
    manuscripts: {
        name: 'Manuscripts'
    },
    skips: {
        name: 'Raid Skips'
    },

    // WotLK Races
    'race-level80-legacy': {
        name: 'Level 80 (Legacy)',
        short: 'Level 80',
        description: 'Ranks players based on when they obtained the Level 80 (Legacy) achievement on any single character.'
    },
    'race-hero-wrath': {
        name: 'Glory of the Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Hero achievement on any single character.'
    },
    'race-glory-raider': {
        name: 'Glory of the Raider',
        short: 'Glory Raider',
        description: 'Ranks players based on when they obtained the Glory of the Raider achievement on any single character.'
    },
    'race-glory-ulduar': {
        name: 'Glory of the Ulduar Raider',
        short: 'Glory Ulduar',
        description: 'Ranks players based on when they obtained the Glory of the Ulduar Raider achievement on any single character.'
    },
    'race-glory-icecrown': {
        name: 'Glory of the Icecrown Raider',
        short: 'Glory Icecrown',
        description: 'Ranks players based on when they obtained the Glory of the Icecrown Raider achievement on any single character.'
    },

    // Cataclysm Races
    'race-level85-legacy': {
        name: 'Level 85 (Legacy)',
        short: 'Level 85',
        description: 'Ranks players based on when they obtained the Level 85 (Legacy) achievement on any single character.'
    },
    'race-hero-cataclysm': {
        name: 'Glory of the Cataclysm Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Cataclysm Hero achievement on any single character.'
    },
    'race-glory-cataclysm': {
        name: 'Glory of the Cataclysm Raider',
        short: 'Glory Cataclysm',
        description: 'Ranks players based on when they obtained the Glory of the Cataclysm Raider achievement on any single character.'
    },
    'race-glory-firelands': {
        name: 'Glory of the Firelands Raider',
        short: 'Glory Firelands',
        description: 'Ranks players based on when they obtained the Glory of the Firelands Raider achievement on any single character.'
    },
    'race-glory-dragonsoul': {
        name: 'Glory of the Dragon Soul Raider',
        short: 'Glory Dragon Soul',
        description: 'Ranks players based on when they obtained the Glory of the Dragon Soul Raider achievement on any single character.'
    },

    // Pandaria Races
    'race-level90-legacy': {
        name: 'Level 90 (Legacy)',
        short: 'Level 90',
        description: 'Ranks players based on when they obtained the Level 90 (Legacy) achievement on any single character.'
    },
    'race-hero-pandaria': {
        name: 'Glory of the Pandaria Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Pandaria Hero achievement on any single character.'
    },
    'race-challenge-pandaria': {
        name: 'Challenge Conqueror: Gold',
        short: 'Challenge',
        description: 'Ranks players based on when they obtained the Challenge Conqueror: Gold achievement (Mists of Pandaria) on any single character.'
    },
    'race-glory-pandaria': {
        name: 'Glory of the Pandaria Raider',
        short: 'Glory Pandaria',
        description: 'Ranks players based on when they obtained the Glory of the Pandaria Raider achievement on any single character.'
    },
    'race-glory-thundering': {
        name: 'Glory of the Thundering Raider',
        short: 'Glory Thundering',
        description: 'Ranks players based on when they obtained the Glory of the Thundering Raider achievement on any single character.'
    },
    'race-glory-orgrimmar': {
        name: 'Glory of the Orgrimmar Raider',
        short: 'Glory Orgrimmar',
        description: 'Ranks players based on when they obtained the Glory of the Orgrimmar Raider achievement on any single character.'
    },

    // WoD Races
    'race-level100-legacy': {
        name: 'Level 100 (Legacy)',
        short: 'Level 100',
        description: 'Ranks players based on when they obtained the Level 100 (Legacy) achievement on any single character.'
    },
    'race-hero-draenor': {
        name: 'Glory of the Draenor Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Draenor Hero achievement on any single character.'
    },
    'race-challenge-draenor': {
        name: 'Challenge Warlord: Gold',
        short: 'Challenge',
        description: 'Ranks players based on when they obtained the Challenge Warlord: Gold achievement (Warlord of Draenor) on any single character.'
    },
    'race-glory-draenor': {
        name: 'Glory of the Draenor Raider',
        short: 'Glory Draenor',
        description: 'Ranks players based on when they obtained the Glory of the Draenor Raider achievement on any single character.'
    },
    'race-glory-hellfire': {
        name: 'Glory of the Hellfire Raider',
        short: 'Glory Hellfire',
        description: 'Ranks players based on when they obtained the Glory of the Hellfire Raider achievement on any single character.'
    },

    // Legion Races
    'race-level110-legacy': {
        name: 'Level 110 (Legacy)',
        short: 'Level 110',
        description: 'Ranks players based on when they obtained the Level 110 (Legacy) achievement on any single character.'
    },
    'race-hero-legion': {
        name: 'Glory of the Legion Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Legion Hero achievement on any single character.'
    },
    'race-challenge-legion': {
        name: 'Legion Keystone Master',
        short: 'Keystone Master',
        description: 'Ranks players based on when they obtained the Keystone Master achievement on any single character.'
    },
    'race-glory-legion': {
        name: 'Glory of the Legion Raider',
        short: 'Glory Legion',
        description: 'Ranks players based on when they obtained the Glory of the Legion Raider achievement on any single character.'
    },
    'race-glory-tomb': {
        name: 'Glory of the Tomb Raider',
        short: 'Glory Tomb',
        description: 'Ranks players based on when they obtained the Glory of the Tomb Raider achievement on any single character.'
    },
    'race-glory-argus': {
        name: 'Glory of the Argus Raider',
        short: 'Glory Argus',
        description: 'Ranks players based on when they obtained the Glory of the Argus Raider achievement on any single character.'
    },

    // BfA Races
    'race-level120-legacy': {
        name: 'Level 120 (Legacy)',
        short: 'Level 120',
        description: 'Ranks players based on when they obtained the Level 120 (Legacy) achievement on any single character.'
    },
    'race-hero-bfa': {
        name: 'Glory of the Wartorn Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Wartorn Hero achievement on any single character.'
    },
    'race-challenge-bfa': {
        name: 'Battle for Azeroth Keystone Master',
        short: 'Keystone Master',
        description: 'Ranks players based on when they obtained the Battle for Azeroth Keystone Master achievement (any season) on any single character.'
    },
    'race-glory-uldir': {
        name: 'Glory of the Uldir Raider',
        short: 'Glory Uldir',
        description: 'Ranks players based on when they obtained the Glory of the Uldir Raider achievement on any single character.'
    },
    'race-glory-dazaralor': {
        name: "Glory of the Dazar'alor Raider",
        short: "Glory Dazar'alor",
        description: "Ranks players based on when they obtained the Glory of the Dazar'alor Raider achievement on any single character."
    },
    'race-glory-eternal': {
        name: 'Glory of the Eternal Raider',
        short: 'Glory Eternal',
        description: 'Ranks players based on when they obtained the Glory of the Eternal Raider achievement on any single character.'
    },
    'race-glory-nyalotha': {
        name: "Glory of the Ny'alotha Raider",
        short: "Glory Ny'alotha",
        description: "Ranks players based on when they obtained the Glory of the Ny'Alotha Raider achievement on any single character."
    },

    // Shadowlands Races
    'race-level60': {
        name: 'Level 60',
        description: 'Ranks players based on when they obtained the Level 60 achievement on any single character.'
    },
    'race-hero-shadowlands': {
        name: 'Glory of the Shadowlands Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Shadowlands Hero achievement on any single character.'
    },
    'race-challenge-shadowlands': {
        name: 'Shadowlands Keystone Master',
        short: 'Keystone Master',
        description: 'Ranks players based on when they obtained the Shadowlands Keystone Master achievement (any season) on any single character.'
    },
    'race-glory-nathria': {
        name: 'Glory of the Nathria Raider',
        short: 'Glory Nathria',
        description: 'Ranks players based on when they obtained the Glory of the Nathria Raider achievement on any single character.'
    },
    'race-glory-dominant': {
        name: 'Glory of the Dominant Raider',
        short: 'Glory Dominant',
        description: 'Ranks players based on when they obtained the Glory of the Dominant Raider achievement on any single character.'
    },
    'race-glory-sepulcher': {
        name: 'Glory of the Sepulcher Raider',
        short: 'Glory Sepulcher',
        description: 'Ranks players based on when they obtained the Glory of the Sepulcher Raider achievement on any single character.'
    },

    // Dragonflight Races
    'race-level70': {
        name: 'Level 70',
        description: 'Ranks players based on when they obtained the Level 70 achievement on any single character.'
    },
    'race-hero-dragonflight': {
        name: 'Glory of the Dragonflight Hero',
        short: 'Glory Hero',
        description: 'Ranks players based on when they obtained the Glory of the Dragonflight Hero achievement on any single character.'
    },
    'race-challenge-dragonflight': {
        name: 'Dragonflight Keystone Master',
        short: 'Keystone Master',
        description: 'Ranks players based on when they obtained the Dragonflight Keystone Master achievement (any season) on any single character.'
    },
    'race-glory-vault': {
        name: 'Glory of the Vault Raider',
        short: 'Glory Vault',
        description: 'Ranks players based on when they obtained the Glory of the Vault Raider achievement on any single character.'
    },
    'race-glory-aberrus': {
        name: 'Glory of the Aberrus Raider',
        short: 'Glory Aberrus',
        description: 'Ranks players based on when they obtained the Glory of the Aberrus Raider achievement on any single character.'
    },
    'race-glory-dream': {
        name: 'Glory of the Dream Raider',
        short: 'Glory Dream',
        description: 'Ranks players based on when they obtained the Glory of the Dream Raider achievement on any single character.'
    },
    'race-glory-dream-adjusted': {
        name: 'Glory of the Dream Raider (Adjusted)',
        short: 'Glory Dream',
        description: 'Ranks players based on when they obtained the Glory of the Dream Raider achievement on any single character. Times are shown based on the regional opening of Amirdrassil.',
        start: {
            us: 1699974000000, // 2023-11-14T15:00:00.000Z
            eu: 1700020800000, // 2023-11-15T04:00:00.000Z
            tw: 1700089200000, // 2023-11-15T23:00:00.000Z
            kr: 1700089200000
        }
    },

    // War Within Races
    'race-level80': {
        name: 'Level 80',
        description: 'Ranks players based on when they obtained the Level 80 achievement on any single character.'
    },
    'race-glory-delver': {
        name: 'Glory of the Delver',
        short: 'Glory Delver',
        description: 'Ranks players based on when they obtained the Glory of the Delver achievement on any single character.'
    },
    'race-glory-delver-adjusted': {
        name: 'Glory of the Delver (Adjusted)',
        short: 'Glory Delver',
        description: 'Ranks players based on when they obtained the Glory of the Delver achievement on any single character. Times are shown based on the regional opening of Season 1.',
        start: {
            us: 1725980400000, // 2024-09-10T15:00:00.000Z
            eu: 1726027200000, // 2024-09-11T04:00:00.000Z
            tw: 1726095600000, // 2024-09-11T23:00:00.000Z
            kr: 1726095600000
        }
    },
    'race-challenge-tww': {
        name: 'The War Within Keystone Master',
        short: 'Keystone Master',
        description: 'Ranks players based on when they obtained the The War Within Keystone Master achievement (any season) on any single character.'
    },
    'race-challenge-tww-adjusted': {
        name: 'The War Within Keystone Master (Adjusted)',
        short: 'Keystone Master',
        description: 'Ranks players based on when they obtained the The War Within Keystone Master achievement (any season) on any single character. Times are shown based on the regional opening of Mythic+.',
        start: {
            us: 1726585200000, // 2024-09-17T15:00:00.000Z
            eu: 1726632000000, // 2024-09-18T04:00:00.000Z
            tw: 1726700400000, // 2024-09-18T23:00:00.000Z
            kr: 1726700400000
        }
    },
    'race-glory-nerubar': {
        name: 'Glory of the Nerub-ar Raider',
        short: 'Glory Nerub-ar',
        description: 'Ranks players based on when they obtained the Glory of the Nerub-ar Raider achievement on any single character.'
    },
    'race-glory-nerubar-adjusted': {
        name: 'Glory of the Nerub-ar Raider (Adjusted)',
        short: 'Glory Nerub-ar',
        description: 'Ranks players based on when they obtained the Glory of the Nerub-ar Raider achievement on any single character. Times are shown based on the regional opening of Nerub-ar Palace.',
        start: {
            us: 1725980400000, // 2024-09-10T15:00:00.000Z
            eu: 1726027200000, // 2024-09-11T04:00:00.000Z
            tw: 1726095600000, // 2024-09-11T23:00:00.000Z
            kr: 1726095600000
        }
    },
    'race-mythic-nerubar': {
        name: 'Mythic: Nerub-ar Palace',
        short: 'Mythic Nerub-ar',
        description: 'Ranks players based on when they obtained the Mythic: Nerub-ar Palace achievement on any single character.'
    },
    'race-mythic-nerubar-adjusted': {
        name: 'Mythic: Nerub-ar Palace (Adjusted)',
        short: 'Mythic Nerub-ar',
        description: 'Ranks players based on when they obtained the Mythic: Nerub-ar Palace achievement on any single character. Times are shown based on the regional opening of Nerub-ar Palace.',
        start: {
            us: 1726585200000, // 2024-09-17T15:00:00.000Z
            eu: 1726632000000, // 2024-09-18T04:00:00.000Z
            tw: 1726700400000, // 2024-09-18T23:00:00.000Z
            kr: 1726700400000
        }
    }
};

const STATS = [
    { key: 'arenaswon', name: 'Arenas won' },
    { key: 'arenasplayed', name: 'Arenas played' },
    { key: 'bgswon', name: 'Battlegrounds won' },
    { key: 'bgsplayed', name: 'Battlegrounds played' },
    { key: 'rbgswon', name: 'Rated battlegrounds won' },
    { key: 'rbgsplayed', name: 'Rated battlegrounds played' },
    { key: 'quests', name: 'Quests completed' },
    { key: 'dailyquests', name: 'Daily quests completed' },

    { key: 'achievement503', name: '3000 Quests Completed', achievement: 978 },
    { key: 'achievement2141', name: 'Thanks for the Carry!', achievement: 15834 },
    { key: 'achievement15', name: 'What Can I Say? They Love Me.', achievement: 15643 },
    { key: 'achievement9670', name: 'The Joy of Toy', achievement: 15781 },
    { key: 'achievement10687', name: 'Fashionista: Back', achievement: 10687 },
    { key: 'achievement10682', name: 'Fashionista: Chest', achievement: 10682 },
    { key: 'achievement10685', name: 'Fashionista: Feet', achievement: 10685 },
    { key: 'achievement10693', name: 'Fashionista: Hand', achievement: 10693 },
    { key: 'achievement10681', name: 'Fashionista: Head', achievement: 10681 },
    { key: 'achievement10684', name: 'Fashionista: Legs', achievement: 10684 },
    { key: 'achievement10691', name: 'Fashionista: Shirt', achievement: 10691 },
    { key: 'achievement10692', name: 'Fashionista: Shoulder', achievement: 10692 },
    { key: 'achievement10690', name: 'Fashionista: Tabard', achievement: 10690 },
    { key: 'achievement10686', name: 'Fashionista: Waist', achievement: 10686 },
    { key: 'achievement10689', name: 'Fashionista: Weapon & Off-Hand', achievement: 10689 },
    { key: 'achievement10688', name: 'Fashionista: Wrist', achievement: 10688 }
];

const CARDS = {
    'account-rankings': [ 'completion-score', 'achievement-points', 'account-mounts', 'pets-score', 'account-titles', 'account-reputations', 'account-recipes', 'account-quests', 'account-toys', 'account-appearance-sources', 'completion-count', 'achievements', 'feats', 'legacy', 'pets', 'account-appearances', 'alts', 'alts-score', 'honorlevel', 'account-kills' ],
    'account-rankings-obtainable': [ 'completion-score-obtainable', 'achievement-points', 'account-mounts-obtainable', 'pets-score-obtainable', 'account-titles-obtainable', 'account-reputations-obtainable', 'account-recipes-obtainable', 'account-quests-obtainable', 'account-toys-obtainable', 'account-appearance-sources', 'completion-count-obtainable', 'pets-obtainable', 'account-appearances-obtainable', 'alts', 'alts-score', 'honorlevel', 'account-kills' ],
    'character-rankings': [ 'character-mounts', 'character-titles', 'character-reputations', 'character-recipes', 'character-quests', 'character-kills' ],
    'character-rankings-obtainable': [ 'character-mounts-obtainable', 'character-titles-obtainable', 'character-reputations-obtainable', 'character-recipes-obtainable', 'character-quests-obtainable', 'character-kills' ],
    'profession-rankings': [ 'account-recipes-alchemy', 'account-recipes-blacksmithing', 'account-recipes-cooking', 'account-recipes-enchanting', 'account-recipes-engineering', 'account-recipes-inscription', 'account-recipes-jewelcrafting', 'account-recipes-leatherworking', 'account-recipes-tailoring' ],
    'profession-rankings-obtainable': [ 'account-recipes-alchemy-obtainable', 'account-recipes-blacksmithing-obtainable', 'account-recipes-cooking-obtainable', 'account-recipes-enchanting-obtainable', 'account-recipes-engineering-obtainable', 'account-recipes-inscription-obtainable', 'account-recipes-jewelcrafting-obtainable', 'account-recipes-leatherworking-obtainable', 'account-recipes-tailoring-obtainable' ]
};

// taken from Paging, maxLength=5
const MAXPAGE = 100000;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function clean(str) {
    if (!str) return '';
    return str.trim().toLowerCase().replace(/[#/'"()]/g, '').replace(/\s+/g, '-');
}

function slug(str) {
    str = clean(str);

    switch(str) {
        case 'arak-arahm': return 'arakarahm';
        case 'azjol-nerub': return 'azjolnerub';
        default: return str;
    }
}

function canonical(str) {
    return clean(str).replace(/[^a-z0-9-\s]/g, '').replace(/\s+/g, '-');
}

function wowhead(category, wowheadid, id) {
    let link = null;
    let sep = '=';
    switch(category) {
        case 'achievements': link = 'achievement'; break;
        case 'reputations': link = 'faction'; break;
        case 'quests': link = 'quest'; break;
        case 'pets': link = 'battle-pet'; sep = '/'; break;
        case 'mounts': link = 'mount'; sep = '/'; break;
        case 'recipes': link = 'recipe'; sep = '/'; break;
        case 'titles': link = 'title-mask'; sep = '/'; break;
        case 'toys': link = 'item'; id = wowheadid; break;
        case 'heirlooms': link = 'item'; id = wowheadid; break;
        case 'hunterpets': link = 'npc'; break;
        case 'soulshapes': link = 'quest'; break;
        case 'skips': link = 'quest'; break;
        case 'manuscripts': link = 'item'; id = wowheadid; break;
        case 'appearances': link = 'item'; id = wowheadid?.replace('?', '?bonus='); break;
        case 'appearancesources': link = 'item'; id = wowheadid?.replace('?', '?bonus='); break;
        default: link = category;
    }

    if (!id || id < 0) return null;
    return 'https://www.wowhead.com/' + link + sep + id;
}

function icon(name, displayid) {
    if (name?.startsWith('http')) {
        return name;
    }
    if (displayid) {
        return 'https://render.worldofwarcraft.com/us/npcs/zoom/creature-display-' + displayid + '.jpg';
    }
    if (name?.startsWith('/')) {
        return 'https://wow.zamimg.com/images/wow/icons/medium' + name + '.jpg';
    }
    return 'https://render.worldofwarcraft.com/us/icons/36/' + (name || 'inv_misc_note_01') + '.jpg';
}

function getFriendlySourceName(icon) {
    switch(icon) {
        case 'achievement': return 'Achievement';
        case 'currency': return 'Special Currency';
        case 'discovery': return 'Discovery';
        case 'drop': return 'Drop';
        case 'faction': return 'Reputation';
        case 'petbattle': return 'Pet Battle';
        case 'profession': return 'Profession';
        case 'promotion': return 'Promotion';
        case 'quest': return 'Quest';
        case 'treasure': return 'Treasure';
        case 'vendor': return 'Vendor';
        default: return 'Other';
    }
}

function getFriendlyCategoryName(category, format) {
    const definition = LEADERBOARDS[category];
    return (format ? null : definition?.short) || definition?.name || '?';
}

function getDescription(category) {
    return LEADERBOARDS[category]?.description || '?';
}

function getAdjustedStart(category) {
    return LEADERBOARDS[category]?.start;
}

function getCategoriesWithObtainable() {
    return Object.keys(LEADERBOARDS).filter(key => LEADERBOARDS[key + '-obtainable']);
}

function getCategoriesWithAdjusted() {
    return Object.keys(LEADERBOARDS).filter(key => LEADERBOARDS[key + '-adjusted']);
}

function toFixed(num, decimals) {
    return num.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
}

function formatScore(category, score) {
    try {
        // format as number
        return score.toLocaleString();
    } catch(e) {
        // if any errors occur, just return the score as-is
        return score;
    }
}

function formatPercentAndScore(category, score, max) {
    const percent = formatPercent(category, score, max);
    if (percent) {
        return formatScore(category, score) + ' / ' + formatScore(category, max[category]) + ' (' + percent + ')';
    } else {
        return null;
    }
}

function formatPercent(category, score, max) {
    if (max?.[category]) {
        return toFixed(100 * score / max[category], 1) + '%';
    } else {
        return null;
    }
}

function pack(keys) {
    const realms = {};
    for (let key of keys) {
        const parts = key.split('#');
        const realm = parts[0] + '.' + parts[1];

        if (!realms[realm]) realms[realm] = [];
        realms[realm].push(parts[2]);
    }

    const list = [];
    for (let [key, value] of Object.entries(realms)) {
        list.push(key + '.' + value.join('.'));
    }

    return list.join('_');
}

function unpack(str) {
    const keys = [];

    str.split('_').forEach(l => {
        const parts = l.split('.');
        if (parts.length > 2) {
            const region = parts.shift();
            const realm = parts.shift();

            parts.forEach(name => {
                keys.push(region + '#' + realm + '#' + name);
            });
        }
    });

    return keys;
}

function scrollTo(id) {
    try {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    } catch(ignored) {

    }
}

// makes a regex out of user input
function regex(input) {
    const str = input && input.trim();
    if (str && str.length > 0) {
        return new RegExp(str.replace(/[-/\\^$+?.()[\]{}]/g, '\\$&').replaceAll('*', '.*'), 'i');
    } else {
        return null;
    }
}

function escapeCsv(value) {
    if (typeof value === 'number') {
        if (Number.isInteger(value)) return value;
        return toFixed(value, 4);
    }

    if (!value) return '';
    return '"' + value.replaceAll('"', '""') + '"';
}

function download(data, filename, type) {
    const element = document.createElement("a");
    const file = new Blob([ data ], { type });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
}

const sourceicons = [ 'achievement', 'currency', 'discovery', 'drop', 'faction', 'petbattle', 'profession', 'promotion', 'quest', 'treasure', 'vendor', 'other' ];
const factions = [ { key: '', value: 'Neutral' }, { key: 0, value: 'Alliance', color: '#0078ff' }, { key: 1, value: 'Horde', color: '#b30000' } ];
const races = [ { key: '', value: 'Neutral' }, { value: 'Blood Elf', key: 10, faction: 1, color: '#b30000' }, { value: 'Dark Iron Dwarf', key: 34, faction: 0, color: '#0078ff' }, { value: 'Dracthyr (A)', key: 52, faction: 0, color: '#0078ff' }, { value: 'Dracthyr (H)', key: 70, faction: 1, color: '#b30000' }, { value: 'Draenei', key: 11, faction: 0, color: '#0078ff' }, { value: 'Dwarf', key: 3, faction: 0, color: '#0078ff' }, { value: 'Earthen (A)', key: 85, faction: 0, color: '#0078ff' }, { value: 'Earthen (H)', key: 84, faction: 0, color: '#b30000' }, { value: 'Gnome', key: 7, faction: 0, color: '#0078ff' }, { value: 'Goblin', key: 9, faction: 1, color: '#b30000' }, { value: 'Highmountain Tauren', key: 28, faction: 1, color: '#b30000' }, { value: 'Human', key: 1, faction: 0, color: '#0078ff' }, { value: 'Kul Tiran', key: 32, faction: 0, color: '#0078ff' }, { value: 'Lightforged Draenei', key: 30, faction: 0, color: '#0078ff' }, { value: "Mag'har Orc", key: 36, faction: 1, color: '#b30000' }, { value: 'Mechagnome', key: 37, faction: 0, color: '#0078ff' }, { value: 'Night Elf', key: 4, faction: 0, color: '#0078ff' }, { value: 'Nightborne', key: 27, faction: 1, color: '#b30000' }, { value: 'Orc', key: 2, faction: 1, color: '#b30000' }, { value: 'Pandaren (A)', key: 25, faction: 0, color: '#0078ff' }, { value: 'Pandaren (H)', key: 26, faction: 1, color: '#b30000' }, { value: 'Pandaren (N)', key: 24 }, { value: 'Tauren', key: 6, faction: 1, color: '#b30000' }, { value: 'Troll', key: 8, faction: 1, color: '#b30000' }, { value: 'Undead', key: 5, faction: 1, color: '#b30000' }, { value: 'Void Elf', key: 29, faction: 0, color: '#0078ff' }, { value: 'Vulpera', key: 35, faction: 1, color: '#b30000' }, { value: 'Worgen', key: 22, faction: 0, color: '#0078ff' }, { value: 'Zandalari Troll', key: 31, faction: 1, color: '#b30000' } ];
const classes = [ { key: '', value: 'Neutral' }, { key: 14, value: 'Adventurer', color: '#9d9d9d' }, { key: 6, value: 'Death Knight', color: '#c41e3b' }, { key: 12, value: 'Demon Hunter', color: '#a330c9' }, { key: 11, value: 'Druid', color: '#ff7c0a' }, { key: 13, value: 'Evoker', color: '#33937f' }, { key: 3, value: 'Hunter', color: '#aad372' }, { key: 8, value: 'Mage', color: '#68ccef' }, { key: 10, value: 'Monk', color: '#00ffba' }, { key: 2, value: 'Paladin', color: '#f48cba' }, { key: 5, value: 'Priest', color: '#f0ebe0' }, { key: 4, value: 'Rogue', color: '#fff468' }, { key: 7, value: 'Shaman', color: '#2359ff' }, { key: 9, value: 'Warlock', color: '#9382c9' }, { key: 1, value: 'Warrior', color: '#c69b6d' } ];
const unobtainables = [ { key: '', value: 'No' }, { key: 1, value: 'Unobtainable' }, { key: 2, value: 'Never Implemented' }, { key: 3, value: 'Obtainable for Limited Time' }, { key: 4, value: 'Bugged' } ];
const covenants = [ { key: '', value: 'Neutral' }, { key: 1, value: 'Kyrian' }, { key: 2, value: 'Venthyr' }, {key: 3, value: 'Night Fae' }, { key: 4, value: 'Necrolord' } ];
const binds = [ { key: '', value: 'on Pickup' }, { key: 1, value: 'on Use' } ];
const noyes = [ { key: '', value: 'No' }, { key: true, value: 'Yes' } ];

const professions = [ 'Alchemy', 'Archaeology', 'Blacksmithing', 'Cooking', 'Enchanting', 'Engineering', 'Gnomish Engineering', 'Goblin Engineering', 'Fishing', 'Herbalism', 'Inscription', 'Jewelcrafting', 'Leatherworking', 'Mining', 'Skinning', 'Tailoring' ];
const questtypes = [ 'Normal', 'Daily', 'Weekly', 'Seasonal', 'Repeatable', 'World Quest', 'Bonus Objective', 'Tracking' ];
const slots = [ 'Back', 'Chest', 'Feet', 'Hands', 'Head', 'Legs', 'Off Hand', 'Shirt', 'Shoulder', 'Tabard', 'Waist', 'Weapon', 'Wrist', 'Other' ];
const geartypes = [ 'Cloth', 'Leather', 'Mail', 'Plate', 'One-Handed Axe', 'Two-Handed Axe', 'One-Handed Mace', 'Two-Handed Mace', 'One-Handed Sword', 'Two-Handed Sword', 'Bow', 'Crossbow', 'Dagger', 'Fist Weapon', 'Gun', 'Polearm', 'Staff', 'Wand', 'Warglaives', 'Shield', 'Off Hand', 'Other' ];
const qualities = [ { key: 0, value: 'Poor' }, { key: 1, value: 'Common' }, { key: 2, value: 'Uncommon' }, { key: 3, value: 'Rare' }, { key: 4, value: 'Epic' }, { key: 5, value: 'Legendary' }, { key: 6, value: 'Artifact' }, { key: 7, value: 'Heirloom' } ];

const patches = {
    'v1': ['1.x', '1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9', '1.10', '1.11', '1.12'],
    'v2': ['2.x', '2.0', '2.1', '2.2', '2.3', '2.4'],
    'v3': ['3.x', '3.0', '3.1', '3.2', '3.3'],
    'v4': ['4.x', '4.0', '4.1', '4.2', '4.3'],
    'v5': ['5.x', '5.0', '5.1', '5.2', '5.3', '5.4'],
    'v6': ['6.x', '6.0', '6.1', '6.2'],
    'v7': ['7.x', '7.0', '7.1', '7.1.5', '7.2', '7.2.5', '7.3', '7.3.5'],
    'v8': ['8.x', '8.0', '8.1', '8.1.5', '8.2', '8.2.5', '8.3'],
    'v9': ['9.x', '9.0', '9.0.5', '9.1', '9.1.5', '9.2', '9.2.5', '9.2.7'],
    'v10': ['10.x', '10.0', '10.0.5', '10.0.7', '10.1', '10.1.5', '10.1.7', '10.2', '10.2.5', '10.2.6', '10.2.7'],
    'v11': ['11.x', '11.0']
};
patches.all = [].concat(...Object.values(patches));

const collator = new Intl.Collator('en', { sensitivity: 'base', numeric: true });

const utils = { MAXPAGE, CARDS, STATS, sleep, clean, slug, canonical, wowhead, icon, getFriendlySourceName, getFriendlyCategoryName, getDescription, getAdjustedStart, toFixed, formatScore, formatPercentAndScore, formatPercent, pack, unpack, scrollTo, regex, escapeCsv, download, sourceicons, factions, races, classes, unobtainables, covenants, binds, noyes, professions, questtypes, slots, geartypes, qualities, patches, collator, getCategoriesWithObtainable, getCategoriesWithAdjusted };
export default utils;